<template>
    <div>
        <!-- <h1 class="text-uppercase text-center text-primary">Prochainement</h1> -->
        <b-card class="min-vh-100"
        >
            <b-row>
                <b-col sm="4" class="text-center offset-md-4">
                    <h3 style="line-height:100px;height:100px;text-transform:uppercase;">Notification</h3>
                </b-col>
                
            </b-row>
            <hr>
            <b-row class="d-flex justify-content-center text-center">
                <b-col sm="1">
                    <b-alert show variant="info" class="p-3 text-center" style="color:#000 !important;"><feather-icon class="text-body align-middle mr-25" style="color:#080 !important;" icon="CheckCircleIcon" size="50"/></b-alert>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-alert show variant="success" class="p-3 text-center" style="color:#000 !important;">Le devis {{projet_no}} a été classé sans suite.</b-alert>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BCard,BRow,BCol,BAlert } from 'bootstrap-vue'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAlert
  },
  data(){
    return {
        projet_no:null
    }
  },
  created(){
    let devisId=this.$route.params.id
    this.$http.get(`projet/historisationSolliciter/${devisId}`).then(response => {
        console.log(response);
        if(response.data.success)
        {
            this.projet_no = response.data.devis_no;
        }
        else
        {
            this.$router.push('/')
        }
    })
    .catch(err => {
        console.log(err)
    })
  }
}
</script>

<style>
.img-proche
{
    padding:5% 10%;
}
@media(max-width:768px)
{
    .img-proche
    {
        margin-top:50px;
        padding: 0;
    }
}
</style>